import './DataRow.scss';
import { MdDeleteOutline } from 'react-icons/md';
import { MdEdit } from 'react-icons/md';
import { AiOutlineSelect } from 'react-icons/ai';
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2';
import { HiOutlineClipboardCheck } from 'react-icons/hi';

const DataRow = (props) => {

    const parentProps = props.props;

    return (
        <div className="DataRow info-db line">
            <div className="DataRow__container">
                <div className="DataRow__container__short">
                    {
                        parentProps.title.includes("link") ?
                            <a href={props.data[parentProps.filterBy]} className="DataRow__container__short__link">{props.data[parentProps.filterBy]}</a>
                            :
                            <p className="DataRow__container__short__text">{props.data[parentProps.filterBy]}</p>
                    }
                </div>
                <div className="DataRow__options">
                    {/* Copy to clipboard */}
                    {
                        parentProps.copyData !== false ?
                            <div className="DataRow__container__copy">
                                <button className="DataRow__container__copy__link" onClick={e => props.onEvento("Copy", props.data)}><HiOutlineClipboardDocumentList /></button>
                            </div>
                            :
                            null
                    }
                    {/* View button */}
                    <div className="DataRow__container__view">
                        <button className="DataRow__container__view__link" onClick={e => props.onEvento("View", props.data[parentProps.identifier])}><AiOutlineSelect /></button>
                    </div>
                    {/* Edit button */}
                    <div className="DataRow__container__edit">
                        <button className="DataRow__container__edit__link" onClick={e => props.onEvento("Edit", props.data[parentProps.identifier])}><MdEdit /></button>
                    </div>
                    {/* Delete button */}
                    <div className="DataRow__container__delete">
                        <button className="DataRow__container__delete__link" onClick={e => props.onEvento("Delete", props.data[parentProps.identifier])}><MdDeleteOutline /></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataRow;