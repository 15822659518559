import './FormElement.scss';

const FormElement = ({ label, name, type, value, onChange, error, placeholder, autocomplete }) => {
    return (
        <div className="form-element line-input">
            <div className='form-element__label align-items-center' htmlFor={name}>
                <h2>{label}</h2>
            </div>
            <input
                className={`form-element__input ${error && (error.attribute === name || (error.attribute?.length > 0 && error.attribute.includes(name))) ? 'form-element__input__error' : ''}`}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                autoComplete={autocomplete}
            />
            {
                error && (error.attribute === name || (error.attribute?.length > 0 && error.attribute.includes(name))) && (
                    <div className="form-element__error">
                        <span className="form-element__error__message">
                            {error.reason}
                        </span>
                    </div>
                )
            }
        </div>
    )
};

export default FormElement;