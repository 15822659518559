import { loginWithGoogle, auth } from "services/firebase";
import { useNavigate } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import useStateHandler from "hooks/useGlobalState";
import "./SignInWithGoogle.scss";

const SignInWithGoogle = () => {

    const { state, stateHandler } = useStateHandler();
    const navigate = useNavigate();

    const loginWithGoogleHandler = async (e) => {
        e.preventDefault();
        const res = await loginWithGoogle();
        if (res.ok) {
            for (const key in state) {
                stateHandler.remove(key);
            }
            navigate('/dashboard');
        }
    }

    // Sign in with Google with icon from react-icons
    return (
        <button className="google-button" onClick={loginWithGoogleHandler}>
            <img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="google" className="google-icon" />
            <span className="google-text">Sign in with Google</span>
        </button>
    );

};

export default SignInWithGoogle;
