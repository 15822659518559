import userServices from "./user.service";
import linkServices from "./links.service";
import adsServices from "./ads.service";

const services = {
    user: userServices,
    link: linkServices,
    ad: adsServices
}

export default services;