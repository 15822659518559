import DataGetter from "components/GenericTable/DataGetter/DataGetter";
import LinksService from "services/links.service";
import NavBar from "components/NavBar/NavBar";
import CreateLink from "components/CreateLink/CreateLink";

const Links = () => {

    return (
        <>
            <NavBar />
            <div className="links">
                <div className="links__container">
                    <div className="links__container__content">
                        <DataGetter
                            title="Links"
                            identifier="link_id"
                            filterBy="link_short"
                            createData={LinksService.createLink}
                            getData={LinksService.getLink}
                            getAllData={LinksService.getLinksUser}
                            deleteData={LinksService.deleteLink}
                            updateData={LinksService.updateLink}
                        />
                    </div>
                </div>
            </div>
        </>
    )

};

export default Links;