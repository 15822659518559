//INITIAL_STATE OF THE GLOBAL STATE. WE WILL READ FROM LOCALSTORAGE AND INDEXEDDB
import Utils from "../../utils";
import services from 'services';

const INITIAL_STATE = {
    services
};

const MainReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case '@global/set':  {
            return {
                ...Utils.addAttributeToObject(action.payload.attribute, action.payload.value, state)
            }
        }

        case '@global/remove':  {
            return {
                ...Utils.removeAttributeFromObject(action.payload.attribute, state)
            }
        }

        default: {
            return state;
        }
    } 
};

export default MainReducer;
export {INITIAL_STATE};