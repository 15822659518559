import React, { useState } from "react";
import FormElement from "components/FormElement/FormElement";
import FormSubmit from "components/FormSubmit/FormSubmit";
import ShortLinkDisplay from "components/ShortLinkDisplay/ShortLinkDisplay";
import useStateHandler from "hooks/useGlobalState";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'services/firebase.js';
import LinkService from 'services/links.service.js';
import "./CreateLink.scss";

const CreateLink = (props) => {

    const { state, stateHandler } = useStateHandler();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [user] = useAuthState(auth);

    const ErrorHandler = (data) => {
        if (data.attribute && data.reason) {
            if (data.attribute === 'link_short' && (data.reason.includes('duplicate') || data.reason.includes('exists') || data.reason.includes('unique'))) {
                setError({ attribute: 'link_short', reason: 'Short URL already exists. Please, try another one' });
            } else {
                setError(data);
            }
        } else {
            setError({ attribute: '', reason: 'Something went wrong' });
        }
    }

    const dataValidation = (data) => {
        const { link_long, link_short } = data;
        if (!link_long) {
            ErrorHandler({ attribute: 'link_long', reason: 'Long URL is required' });
        }
        const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
        if (!urlRegex.test(link_long)) {
            ErrorHandler({ attribute: 'link_long', reason: 'Long URL is not valid' });
        }
        if (!link_short || link_short.length < 4) {
            ErrorHandler({
                attribute: 'link_short',
                reason: 'Short URL must be at least 4 characters long'
            });
        }
        return error ? false : true;
    }

    async function onSubmit(e) {
        e.preventDefault();

        const formData = {
            link_long: e.target.elements['link_long'].value,
            link_short: e.target.elements['link_short'].value,
            link_description: e.target.elements['link_description'].value
        };

        setError(null);
        setSuccess(null);

        if (!dataValidation(formData)) {
            return;
        }

        const response = await LinkService.createLink({
            token: await user.getIdToken(),
            ...formData
        });

        if (response.ok) {
            setError(null);
            setSuccess(response.body.link_short);
            // Get all keys in state that start with 'Links_'
            const adsKeys = Object.keys(state).filter(key => key.startsWith('Links_'));
            // Remove all keys in state that start with 'Links_'
            adsKeys.forEach(key => stateHandler.remove(key));
        } else {
            ErrorHandler(response.body);
        }
    }

    return (
        <div className="CreateLink">
            {
                !props.formRef && <h2>New Shortened Link</h2>
            }
            <form onSubmit={onSubmit} ref={props.formRef}>
                <FormElement
                    label="Loooooong and boring URL"
                    name="link_long"
                    type="text"
                    error={error}
                    placeholder="https://www.example.com"
                />
                <FormElement
                    label="Shortened URL"
                    name="link_short"
                    type="text"
                    error={error}
                    placeholder="shrt"
                />

                <FormElement
                    label="Description (only visible to you)"
                    name="link_description"
                    type="text"
                    error={error}
                    placeholder="This is a link to my favorite website"
                />
                {
                    !props.formRef && <FormSubmit text="Create" />
                }
            </form>
            {success && <ShortLinkDisplay shortLink={success} />}
        </div>
    );
};

export default CreateLink;