import fetchWrapper from "./fetch.service";
import config from "config/default.config";
const API_URL = config.backend.url + '/user/';
const VISITS = config.backend.url + '/visits/';

async function getAllVisits(params) {
    const { response, data } = await fetchWrapper.get(VISITS, { injectToken: params.token });
    return {
        response,
        body: data,
        status_code: response.status,
        ok: response.ok
    }
}

const userService = {
    getAllVisits,
};

export default userService;
