import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DetallesLink from 'components/DetallesLink/DetallesLink';
import DetallesAd from 'components/DetallesAd/DetallesAd';
import React from 'react';

const InfoModal = (props) => {

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            keyboard={true}
            centered
        >
                <Modal.Header className='text-center'>
                    <Modal.Title>View {props.title.toLowerCase()}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                        {
                            props?.data?.link_id !== undefined ?
                            <DetallesLink 
                                visits = {props.data.visits}
                                link_id = {props.data.link_id}
                                link_short = {props.data.link_short}
                                link_long = {props.data.link_long}
                                link_description = {props.data.link_description}
                                createdAt = {props.data.createdAt}
                            />
                            :
                            <DetallesAd 
                                visits = {props.data.visits}
                                ad_id = {props.data.ad_id}
                                ad_name = {props.data.ad_name}
                                ad_info = {props.data.ad_info}
                                createdAt = {props.data.createdAt}
                            />
                        }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>Close</Button>
                </Modal.Footer>
        </Modal>
    );
}

export default InfoModal;