import './DetallesAd.scss'

const DetallesAd = (props) => {
    function changeFormatDate(stringDate) {
        const date = new Date(stringDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formatter = new Intl.DateTimeFormat('es-ES', options);

        return formatter.format(date);
    }
    function mostrarAd() {
        if (props.ad_info.ad_type === "image") {
            return <img className='image' src={props.ad_info.image} alt="ad_image" />
        } else {
            return <div className='iframe' dangerouslySetInnerHTML={{ __html: props.ad_info.frame }} />
        }
    }

    return (
        <div className="ad d-flex flex-column align-items-center">
            <div className='align-items-center'>
                <div className='align-items-center'>
                    <h4>Ad_name</h4>
                </div>
                <div className='text-center'>
                    {props.ad_name}
                </div>
            </div>

            <div >
                <div>
                    <h4>Ad_type</h4>
                </div>
                <div className='text-center'>
                    {props.ad_info.ad_type}
                </div>
            </div>

            <div>
                <div className='text-center'>
                    {
                        props.ad_info.ad_type === "image" ?
                            <h4>Ad_imagen</h4>
                            :
                            <h4>Ad_iframe</h4>
                    }
                </div>
                <div className='text-center' >
                    {mostrarAd()}
                </div>
            </div>
            <div className='align-items-center'>
                <div className='align-items-center'>
                    <h4>Visits</h4>
                </div>
                <div className='text-center'>
                    <div>
                        {props.visits.length}
                    </div>
                </div>
            </div>
            <div>
                <div className='text-center'>
                    <h4>Created At</h4>
                </div>
                <div className='text-center'>
                    {changeFormatDate(props.createdAt)}
                </div>
            </div>

        </div>
    );
}

export default DetallesAd;