import NavBar from "components/NavBar/NavBar";
import services from "services/index.js";
import DataGetter from "components/GenericTable/DataGetter/DataGetter";

const Ads = () => {

    return (
        <>
            <NavBar />
            <div className="ads">
                <div className="dashboard__container">
                    <div className="dashboard__container__content">
                        <DataGetter
                            title="Ads"
                            filterBy="ad_name"
                            identifier="ad_id"
                            createData={services.ad.createAd}
                            updateData={services.ad.updateAd}
                            deleteData={services.ad.deleteAd}
                            getAllData={services.ad.getAdsUser}
                            getData={services.ad.viewAd}
                            copyData={false}
                        />
                    </div>
                </div>
            </div>
        </>
    )

};

export default Ads;