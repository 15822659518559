import { useEffect, useState } from 'react';
import './Visits.scss';
import useStateHandler from 'hooks/useGlobalState';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'services/firebase';
import userService from 'services/user.service';

const Visits = (props) => {

    const { state, stateHandler } = useStateHandler();
    const [totalVisits, setTotalVisits] = useState(state["visits"] ?? 0);
    const [linkVisits, setLinkVisits] = useState(state["Links_visits"] ?? 0);
    const [adVisits, setAdVisits] = useState(state["Ads_visits"] ?? 0);
    const [user] = useAuthState(auth);

    useEffect(() => {

        const getVisits = async () => {
            try {
                if (state["visits"]) {
                    return;
                }
                const response = await userService.getAllVisits({ token: user.accessToken });
                if (response.status_code !== 200) {
                    return stateHandler.set("visits", "...");
                }
                setTotalVisits(response.body.total);
                setLinkVisits(response.body.links);
                setAdVisits(response.body.ads);
                stateHandler.set("visits", response.body.total);
                stateHandler.set("Links_visits", response.body.links);
                stateHandler.set("Ads_visits", response.body.ads);

            } catch (error) {
                console.log(error);
                return stateHandler.set("visits", "...");
            }
        }
        getVisits();
    }, [state["visits"]]);

    return (
        <div className="Visits">
            <h2>Visits</h2>
            <div className="Visits__container">
                <div className='Visits__container__group'>
                    <div className="Visits__container__item">
                        <h5>Links</h5>
                        <p>{linkVisits}</p>
                    </div>
                    <div className="Visits__container__item">
                        <h5>Ads</h5>
                        <p>{adVisits}</p>
                    </div>
                </div>
                <div className='Visits__container__group'>
                    <div className="Visits__container__item">
                        <h5>Total</h5>
                        <p>{totalVisits}</p>
                    </div>
                </div>
            </div>
        </div >
    );

}

export default Visits;