import NavBar from "components/NavBar/NavBar";
import { Outlet } from "react-router-dom";
import "./Full.scss";

const FullLayout = () => {
    return (
        <>
            <NavBar />
            <div className="full-layout">
                <div className="full-layout__content">
                    <div className="full-layout__inner">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FullLayout;
