import SignInWithGoogle from 'components/SignInWithGoogle/SignInWithGoogle';
import FormElement from 'components/FormElement/FormElement';
import FormSubmit from 'components/FormSubmit/FormSubmit';
import { loginWithUsernameAndPassword, auth } from 'services/firebase';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import './Login.scss';
import useStateHandler from 'hooks/useGlobalState';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useEffect } from 'react';

const Login = () => {

    const { state, stateHandler } = useStateHandler();
    const [user] = useAuthState(auth);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();
        const response = await loginWithUsernameAndPassword(email, password);
        if (response.ok || (response.user_id && response.token) ) {
            for (const key in state) {
                stateHandler.remove(key);
            }
            navigate('/dashboard');
        } else {
            setError(response.error ?? response);
        }
    }

    return (
        <>
            <div>
                <h2>Sign In</h2>
                <form className="login-form" method='POST'>
                    <FormElement
                        label="Email"
                        name="user_email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        error={error}
                        placeholder="johndoe@example.com"
                        autocomplete="email"
                    />
                    <FormElement
                        label="Password"
                        name="user_pass"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        error={error}
                        placeholder="********"
                        autocomplete="current-password"
                    />
                    <FormSubmit text="Sign In" onSubmit={onSubmit} />
                    <div className="login-form__forgot-wrapper">
                        <a className="login-form__forgot" href="/forgot-password">Forgot password?</a>
                    </div>
                    <div className="login-form__oauth">
                        <SignInWithGoogle />
                    </div>
                    <div className='login-form__register'>
                        No account? <a href="/register" className="login__register" >Register</a>
                    </div>
                </form>
            </div>
        </>
    );
}


export default Login;