import DataList from "components/GenericTable/DataList/DataList";
import CreateModal from "components/CreateModal/CreateModal";
import InfoModal from "components/InfoModal/InfoModal";
import EditModal from "components/EditModal/EditModal";
import { useState, useEffect } from "react";
import useGlobalState from "hooks/useGlobalState";
import "./DataView.scss";

const DataView = (props) => {

    const { state, stateHandler } = useGlobalState();
    const stateData = state[props.title + "_" + props.page] ?? [];
    const [ogData, setOgData] = useState(stateData ?? []);
    const [data, setData] = useState(stateData ?? []);

    const [editShow, setEditShow] = useState(false);
    const [viewShow, setViewShow] = useState(false);
    const [createShow, setCreateShow] = useState(false);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        setOgData(stateData);
        setData(stateData);
    }, [stateData, props.page]);

    function name() {
        return "Create new " + props.title.toLowerCase();
    }

    function filterData(e) {
        const value = e.target.value;
        if (value === "") {
            setData(ogData);
        } else {
            // const newData = ogData.filter(item => item[props.filterBy].toLowerCase().includes(value.toLowerCase()));
            // setData(newData);
            
            // Get all keys that start with props.title in state
            const keys = Object.keys(state).filter(key => key.startsWith(props.title) && !key.startsWith(props.title + "_t"));
            // Get all data from state
            let allData = [];
            keys.forEach(key => {
                allData = allData.concat(state[key]);
            });
            // Filter the data
            const newData = allData.filter(item => {
                return item[props.filterBy].toLowerCase().includes(value.toLowerCase());
            });
            setData(newData);

        }
    }

    function onDelete(deleted) {
        // Get all keys in state that start with the title of the data
        const keys = Object.keys(state).filter(key => key.startsWith(props.title));
        // Delete the data from the state
        keys.forEach(key => {
            stateHandler.remove(key);
        });
    }

    function onView(id) {
        setViewShow(true);
        const select = ogData.find(item => item[props.identifier] === id);
        setSelected(select);
    }

    function onEdit(id) {
        setEditShow(true);
        const select = ogData.find(item => item[props.identifier] === id);
        setSelected(select);
    }

    return (
        <div className="DataView__container">
            <div className="DataView__container__header">
                <h2>My {props.title}</h2>
            </div>
            <div className="text-inputs flex-container flex-spaced" style={{
                width: 'unset',
            }}>
                <input type="text" name="title" id="filtro" onChange={filterData} placeholder={props.title} />
                <button style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                }} onClick={() => setCreateShow(true)}>{name()}</button>
            </div>
            <div className="DataView__container__content">
                <DataList data={data} props={props} onDelete={onDelete} onView={onView} onEdit={onEdit} />
            </div>
            <EditModal
                show={editShow}
                onHide={() => setEditShow(false)}
                data={selected}
                title={props.title}
            >

            </EditModal>
            <InfoModal
                show={viewShow}
                onHide={() => setViewShow(false)}
                data={selected}
                title={props.title}
            >
            </InfoModal>
            <CreateModal
                show={createShow}
                onHide={() => setCreateShow(false)}
                title={props.title}
            ></CreateModal>

        </div>
    )

}

export default DataView;
