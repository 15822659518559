import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'services/firebase';
import useStateHandler from 'hooks/useGlobalState';
import DataView from '../DataView/DataView';
import DataPagination from '../DataPagination/DataPagination';

const DataGetter = (props) => {

    let newProps = { ...props };
    const { state, stateHandler } = useStateHandler();
    const [user] = useAuthState(auth);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(state[props.title + "_totalPages"] ?? 1);

    async function getPageFromServer(user) {
        const limit = 5;
        try {
            const response = await props.getAllData({ token: user.accessToken, query : {
                limit : limit,
                offset : (page - 1) * limit
            } });
            if (response.status_code !== 200) {
                return stateHandler.set(props.title, []);
            }
            setTotalPages(Math.ceil(response.body.total / limit));
            stateHandler.set(props.title + "_totalPages", Math.ceil(response.body.total / limit));
            stateHandler.set(props.title + "_" + page, response.body[props.title.toLowerCase()] ?? []);
        } catch (error) {
            console.log(error);
            return stateHandler.set(props.title, []);
        }
    }

    useEffect(() => {
        if (state[props.title + "_" + page]) {
            return;
        }
        getPageFromServer(user);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, state]);

    if (!state[props.title + "_" + page]) {
        return "Loading...";
    }

    return (
        <>
            <div className="DataView__container">
                <DataView {...newProps} data={state[props.title]} page={page} />
                <div className="DataView__container__pagination">
                    {
                        totalPages > 1 &&
                        <DataPagination numberOfPages={totalPages} current={page} setPage={setPage} />
                    }
                </div>
            </div>
        </>
    );
}

export default DataGetter;