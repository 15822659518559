/**
 * The DEFINITIVE HOOK 
 * Manage the global state with SYNCHRONOUS BEHAVIOUR! (You can actually get current Value that should be available on next render)
 * Very easy to use
 */
import actions from 'store/actions';
import config from 'config/default.config';
import store from 'store';
import Utils from 'utils';

import { useDispatch, useSelector } from 'react-redux';

export default function useStateHandler() {
    // const CACHE_PATH = config.cache.cache_path;
    const dispatch = useDispatch();
    const state = useSelector(state => state); //By importing useSelector, this will tell React that your component needs to be marked as dirty whenever the state changes so that it can re-render.

    //To do in the future: Check if reasigning this object on every render is a problem peformance wise. If so, use useMemo (maybe it's a problem because it will not have the new values of store.)
    const stateHandler = {
        get: () => { return store.getState() }, //Returns the current value of state. No need to wait until next render to see it. Not recommended but hey it's there if you need it.
        dispatch: (action) => { dispatch(action) },
        contains: (attribute) => { return Utils.objectContains(attribute, store.getState()) }, //Does deep exploration
        getValue: (attribute) => { return Utils.getAttributeValue(attribute, store.getState()) }, //Does deep exploration
        set: (attribute, value, opts) => { dispatch(actions.set(attribute, value, opts)) }, //Does deep exploration
        remove: (attribute) => { dispatch(actions.remove(attribute)) }, //Does deep exploration
        actions,
    }

    // const cacheHandler = {
    //     getCache: () => { return Utils.getAttributeValue(CACHE_PATH, store.getState()) },
    //     isCacheEmpty: () => { return [0, 1].includes(Object.keys((Utils.getAttributeValue(CACHE_PATH, store.getState()))).length) }, //Cache will always have at least one attribute (expires_in attribute), but we put 0 just in case XD.
    //     isInCache: (attribute) => { return Utils.objectContains(CACHE_PATH + "." + attribute, store.getState()) },

    //     storeInCache: (attribute, value, opts = {}) => {
    //         const { type, expires_in } = opts;
    //         const valueExpiresIn = (!type || type === "@cache/default" || type === "@cache/persist") ? (Utils.getMSFromTimeUnit(expires_in) || config.cache_value_default_duration) : 0;
    //         const valueToStore = {
    //             timestamp_start: Date.now(),
    //             timestamp_end: Date.now() + valueExpiresIn,
    //             data: value
    //         }

    //         dispatch(actions.set(CACHE_PATH + "." + attribute, valueToStore))

    //         if (!type || type === "@cache/default") { /** If we are in default value, we need to clean the value of the cache */
    //             setTimeout(() => {
    //                 dispatch(actions.remove(CACHE_PATH + "." + attribute));
    //             }, valueExpiresIn)
    //         }
    //     },
    //     removeFromCache: (attribute) => { dispatch(actions.remove(CACHE_PATH + "." + attribute)) },
    //     getCacheValue: (attribute) => {
    //         const cacheValue = Utils.getAttributeValue(CACHE_PATH + "." + attribute, store.getState());

    //         if (!cacheValue) return null;

    //         return {
    //             data: cacheValue.data,
    //             isExpired: cacheValue.timestamp_end <= Date.now()
    //         }
    //     },
    // }

    return { stateHandler, state };
};