import fetchWrapper from "./fetch.service";
import config from "config/default.config";
const API_URL = config.backend.url + '/ad/';

async function createAd(params, token) {
    if (!params.ad_name || !params.ad_info) {
        return { error: "Name and URL are required" };
    }
    const { response, data } = await fetchWrapper.post(API_URL, { body: params, injectToken: token });

    return {
        body: data,
        status_code: response.status,
        ok : response.ok
    }

}

async function getAdsUser(params) {
    const { response, data } = await fetchWrapper.get(API_URL + 'me/?' + new URLSearchParams(params.query).toString(), { injectToken: params.token });
    return {
        body: data,
        status_code: response.status,
        ok: response.ok
    }
}

async function viewAd(ad_id, token) {
    const { response, data } = await fetchWrapper.get(API_URL + ad_id, { injectToken: token });
    return {
        body: data,
        status_code: response.status,
        ok : response.ok
    }

}

async function updateAd(params) {
    const { response, data } = await fetchWrapper.get(API_URL + params.ad_id, { body: params, injectToken: params.token });

    return {
        body: data,
        status_code: response.status,
        ok: response.ok
    }
}

async function deleteAd(ad_id, token) {
    const { response, data } = await fetchWrapper.delete(API_URL + ad_id, { injectToken: token });
    return {
        body: data,
        status_code: response.status,
        ok: response.ok
    }
}

const adsService = {
    createAd,
    getAdsUser,
    viewAd,
    updateAd,
    deleteAd
};

export default adsService;