import NotFound from 'pages/error/NotFound/NotFound';
import ServerError from 'pages/error/ServerError/ServerError';

const errorRoutes = [
    {
        path: '/404',
        element: <NotFound />,
    },
    {
        path: '/500',
        element: <ServerError />,
    },
];

export default errorRoutes;