import React, { useState } from "react";
import { sendResetEmail } from "services/firebase";
import FormElement from "components/FormElement/FormElement";
import FormSubmit from "components/FormSubmit/FormSubmit";
import "./ForgotPassword.scss";

const ForgotPassword = () => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await sendResetEmail(email);
        if(!response || (response.reason && response.attribute)) {
            console.log('Error in response', response);
            setError(response);
        } else {
            setSuccess("Reset email sent, check your inbox.");
            setDisabled(true);
        }
    };

    return (
        <div className="forgot-password">
            <form className="forgot-password__form" onSubmit={handleSubmit}>
                <h1>Forgot Password</h1>
                <FormElement
                    label="Email"
                    name="user_email"
                    type="email"
                    value={email}
                    onChange={e => {
                        setEmail(e.target.value);
                        setError("");
                        setSuccess("");
                        setDisabled(false);
                    }}
                    error={error}
                    placeholder="johndoe@example.com"
                    autocomplete="email"
                />
                <FormSubmit text="Send Reset Email" disabled={disabled} />
                {
                    success && <p className="forgot-password__success">{success}</p>
                }
            </form>
        </div>
    );
};

export default ForgotPassword;