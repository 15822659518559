import React, { useState } from "react";
import FormElement from "components/FormElement/FormElement";
import FormSubmit from "components/FormSubmit/FormSubmit";
import "./CreateAd.scss";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'services/firebase.js';
import AdService from 'services/ads.service.js';
import useStateHandler from "hooks/useGlobalState";

const CreateAd = (props) => {

    const { state, stateHandler } = useStateHandler();
    const [error, setError] = useState(null);
    const [user] = useAuthState(auth);

    const ErrorHandler = (data) => {
        if (data.attribute && data.reason) {
            if (data.attribute === 'ad_name' && (data.reason.includes('duplicate') || data.reason.includes('exists') || data.reason.includes('unique'))) {
                setError({ attribute: 'ad_name', reason: 'Name already exists. Please, try another one' });
            } else {
                setError(data);
            }
        } else {
            setError({ attribute: '', reason: 'Something went wrong' });
        }
    }

    const dataValidation = (data) => {
        const { ad_name, ad_link } = data;
        const urlRegexWithPossibleSearchParams = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
        const iframeRegex = /<iframe.*src="(.*)".*<\/iframe>/;
        if (!ad_name || ad_name.length < 4) {
            ErrorHandler({ attribute: 'ad_name', reason: 'Ad name is required' });
        }
        else if (!ad_link) {
            ErrorHandler({ attribute: 'ad_link', reason: 'Ad link is required' });
        } else if (!urlRegexWithPossibleSearchParams.test(ad_link) && !iframeRegex.test(ad_link)) {
            ErrorHandler({ attribute: 'ad_link', reason: 'URL image or iframe is not valid' });
        }
        return error ? false : true;
    }

    async function onSubmit(e) {
        e.preventDefault();
        const type = e.target.elements['ad_link'].value.includes('iframe') ? 'iframe' : 'image';
        const formData = {
            ad_name: e.target.elements['ad_name'].value,
            ad_link: e.target.elements['ad_link'].value,
        };

        setError(null);

        if (!dataValidation(formData)) {
            return;
        }
        const body = {
            ad_name: formData.ad_name,
            user_id: user.uid,
            ad_info: {
                ad_type: type,
            }
        }

        if (body.ad_info.ad_type === 'image') {
            body.ad_info.image = formData.ad_link;
        } else {
            body.ad_info.frame = formData.ad_link;
        }

        const token = await user.getIdToken();

        const response = await AdService.createAd(
            body, token
        );

        if (response.ok) {
            setError(null);
            //setSuccess(response.body.ad_name + ' created successfully');
            // Get all keys in state that start with 'Ads_'
            const adsKeys = Object.keys(state).filter(key => key.startsWith('Ads_'));
            // Remove all keys in state that start with 'Ads_'
            adsKeys.forEach(key => stateHandler.remove(key));
        } else {
            ErrorHandler(response.body);
        }
    }

    return (
        <div className="CreateAd">
            {
                !props.formRef && <h2>New Shortened Ad</h2>
            }
            <form onSubmit={onSubmit} ref={props.formRef}>
                <FormElement
                    label="Nombre anuncio"
                    name="ad_name"
                    type="text"
                    error={error}
                    placeholder="Ad 1"
                />

                <FormElement
                    label="Url imagen o Iframe"
                    name="ad_link"
                    type="text"
                    error={error}
                    placeholder="https://venta.enterticket.es/rrpp/IVSD"
                />
                {!props.formRef && <FormSubmit text="Create" />}
            </form>
        </div>
    );
};

export default CreateAd;