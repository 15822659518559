import React, { useState } from "react";
import "./ShortLinkDisplay.scss";
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2';

const ShortLinkDisplay = ({ shortLink }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        const url = new URL(window.location.href);
        const redirectUrl = url.protocol + "//" + url.host + "/" + shortLink;
        navigator.clipboard.writeText(redirectUrl);
        setCopied(true);
    };

    return (
        <div className="short-link-display">
            <div className="short-link-display__link">
                <p className="titulo">Este es tu link: </p>
                <div className="agrupacion">
                    <p className="short-link-display__link-text">{shortLink}</p>
                    <button className="short-link-display__link-button" onClick={copyToClipboard}><HiOutlineClipboardDocumentList /></button>
                </div>
            </div>
        </div>
    );
};

export default ShortLinkDisplay;