// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import config from '../config/default.config.js';
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    signInWithPopup,
    GoogleAuthProvider
} from "firebase/auth";
import fetchWrapper from "./fetch.service.js";

// Initialize Firebase
const app = initializeApp(config.firebaseConfig);
const auth = getAuth(app);
const google_provider = new GoogleAuthProvider();

const loginWrapper = async (firebase_response) => {

    try {

        // If the user is not logged in, firebase_response.user is null
        if (!firebase_response.user) {
            return FirebaseErrorHandler(firebase_response);
        }

        // If the user is logged in, we get the user_id from firebase_response.user.uid
        const user_id = firebase_response.user.uid;
        const api_response_get = await fetchWrapper.get(`${config.backend.url}/user/${user_id}`, {
            injectToken: firebase_response.user.accessToken
        });

        if (api_response_get.response.ok) {

            return {
                body: api_response_get.data,
                status_code: api_response_get.response.status,
                ok: api_response_get.response.ok
            }

        } else if (api_response_get.response.status === 404) {

            const api_response_post = await fetchWrapper.post(`${config.backend.url}/user`, {
                body: {
                    user_id: user_id,
                    user_email: firebase_response.user.email,
                    user_name: firebase_response.user.displayName,
                    user_picture: firebase_response.user.photoURL
                }
            }, {
                injectToken: firebase_response.user.accessToken
            });

            if (api_response_post.response.ok) {

                return {

                    body: api_response_post.data,
                    status_code: api_response_post.response.status,
                    ok: api_response_post.response.ok

                }

            } else {

                return api_response_post;

            }
        }

        throw new Error("Something went wrong");

    } catch (error) {
        console.error(error);
        return error;
    }
}

const FirebaseErrorHandler = (error) => {
    console.error(error);
    if (error.code === 'auth/invalid-email') {
        return {
            attribute: 'user_email',
            reason: 'Invalid email address'
        }
    } else if (error.code === 'auth/email-already-in-use') {
        return {
            attribute: 'user_email',
            reason: 'Email already in use'
        }
    } else if (error.code === 'auth/weak-password') {
        return {
            attribute: 'user_password',
            reason: 'Password is too weak'
        }
    } else if (error.code === 'auth/wrong-password') {
        return {
            attribute: 'user_password',
            reason: 'Wrong password'
        }
    } else if (error.code === 'auth/user-not-found') {
        return {
            attribute: 'user_email',
            reason: 'User not found'
        }
    } else if (error.code === 'auth/too-many-requests') {
        return {
            attribute: 'user_email',
            reason: 'Too many requests'
        }
    } else {
        return {
            attribute: 'user_email',
            reason: 'Unknown error'
        }
    }
}

const sendResetEmail = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        return true;
    } catch (error) {
        let errorAux = FirebaseErrorHandler(error);
        return errorAux;
    }
}

// Login with Google

const loginWithGoogle = async () => {
    try {
        const firebase_response = await signInWithPopup(auth, google_provider);
        return await loginWrapper(firebase_response);
    } catch (error) {
        let errorAux = FirebaseErrorHandler(error);
        return errorAux;
    }
}

const loginWithUsernameAndPassword = async (email, password) => {
    try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        return await loginWrapper(res);
    } catch (error) {
        let errorAux = FirebaseErrorHandler(error);
        return errorAux;
    }
}

const registerWithUsernameAndPassword = async (email, password, name) => {
    try {
        const firebase_response = await createUserWithEmailAndPassword(auth, email, password);
        if (firebase_response.user) {
            const user_id = firebase_response.user.uid;
            const api_response = await fetchWrapper.post(`${config.backend.url}/user`, {
                body: {
                    user_id: user_id,
                    user_email: email,
                    user_name: name
                },
                injectToken: firebase_response.user.getIdToken()
            });
            if (api_response.ok) {
                return await loginWrapper(firebase_response);
            } else {
                return api_response;
            }
        }
    } catch (error) {
        let errorAux = FirebaseErrorHandler(error);
        return errorAux;
    }
}

const logout = async () => {
    try {
        const res = await signOut(auth);
        return res;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export {
    auth,
    loginWithUsernameAndPassword,
    registerWithUsernameAndPassword,
    sendResetEmail,
    loginWithGoogle,
    logout
}
