import FormElement from 'components/FormElement/FormElement';
import AdService from 'services/ads.service';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'services/firebase';
import useStateHandler from 'hooks/useGlobalState';
import { useState } from 'react';
import './EditAd.scss'

const EditAd = (props) => {

    const { state, stateHandler } = useStateHandler();
    const [newData, setNewData] = useState({ ...props });
    const [error, setError] = useState(null);
    const [user] = useAuthState(auth);

    const ErrorHandler = (data) => {
        if (data.attribute && data.reason) {
            if (data.attribute === 'ad_name' && (data.reason.includes('duplicate') || data.reason.includes('exists') || data.reason.includes('unique'))) {
                setError({ attribute: 'ad_name', reason: 'Name already exists. Please, try another one' });
            } else {
                setError(data);
            }
        } else {
            setError({ attribute: '', reason: 'Something went wrong' });
        }
    }

    const dataValidation = (data) => {
        const { ad_name, ad_link } = data;
        const urlRegexWithPossibleSearchParams = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
        const iframeRegex = /<iframe.*src="(.*)".*<\/iframe>/;
        if (!ad_name || ad_name.length < 4) {
            ErrorHandler({ attribute: 'ad_name', reason: 'Ad name is required' });
        }
        else if (!ad_link) {
            ErrorHandler({ attribute: 'ad_link', reason: 'Ad link is required' });
        } else if (!urlRegexWithPossibleSearchParams.test(ad_link) && !iframeRegex.test(ad_link)) {
            ErrorHandler({ attribute: 'ad_link', reason: 'URL image or iframe is not valid' });
        }
        return error ? false : true;
    }

    async function onSubmit(e) {
        e.preventDefault();

        const body = {
            ad_id: newData.ad_id,
            ad_name: newData.ad_name,
            ad_info: {
                ad_type: e.target.elements['ad_imagen'].value ? 'image' : 'iframe',
            }
        }

        if (body.ad_info.ad_type === 'image') {
            body.ad_info.image = e.target.elements['ad_imagen'].value;
        } else {
            body.ad_info.frame = e.target.elements['ad_iframe'].value;
        }

        setError(null);

        if (!dataValidation(body)) {
            return;
        }

        const response = await AdService.updateAd({
            token: await user.getIdToken(),
            ...body
        });

        if (response.ok) {
            setError(null);
            // Get all keys in state that start with the title of the data
            const keys = Object.keys(state).filter(key => key.startsWith('Ads'));
            // Delete the data from the state
            keys.forEach(key => {
                stateHandler.remove(key);
            });
        } else {
            ErrorHandler(response.body);
        }
    }

    function mostrarAd() {
        if (props.ad_info.ad_type === "image") {
            return <FormElement
                label="Ad_imagen"
                name="ad_imagen"
                type="text"
                error={null}
                value={props.ad_info.image}
                onChange={e => setNewData({ ...newData, ad_info: { ...newData.ad_info, image: e.target.value } })}
            />
        } else {
            return <FormElement
                label="Ad_iframe"
                name="ad_iframe"
                type="text"
                error={null}
                value={props.ad_info.frame}
                onChange={e => setNewData({ ...newData, ad_info: { ...newData.ad_info, frame: e.target.value } })}
            />
        }
    }

    return (
        <form className="ad d-flex flex-column align-items-center" onSubmit={onSubmit} ref={props.formRef}>
            <div className='align-items-center'>
                <div className='text-center line-input'>
                    <FormElement
                        label="Ad_name"
                        name="ad_name"
                        type="text"
                        error={null}
                        value={newData.ad_name}
                        onChange={e => setNewData({ ...newData, ad_name: e.target.value })}
                    />
                    <div className="focus-line"></div>
                </div>
            </div>

            <div >
                <div className='text-center line-input'>
                    <FormElement
                        label="Ad_type"
                        name="ad_type"
                        type="text"
                        error={null}
                        value={newData.ad_info.ad_type}
                        onChange={e => setNewData({ ...newData, ad_info: { ...newData.ad_info, ad_type: e.target.value } })}
                    />
                    <div className="focus-line"></div>
                </div>
            </div>

            <div>
                <div className='text-center line-input' >
                    {mostrarAd()}
                    <div className="focus-line"></div>
                </div>
            </div>
        </form>
    );
}

export default EditAd;