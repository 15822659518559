import React from "react";
import BaseError from "../BaseError/BaseError";
import NavBar from "../../../components/NavBar/NavBar";
import imgBuena from "../../../assets/images/404.jpg";
import './NotFound.scss';

const NotFound = () => {

    return (
        <>
            <NavBar />
            <BaseError
                errorCode="404"
                errorMessage="Not Found"
                errorDetails="Sorry, the page you are looking for does not exist."
                img = {imgBuena}
            />
        </>
    );

};

export default NotFound;