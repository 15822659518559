import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useRef } from 'react';
import EditLink from 'components/EditLink/EditLink';
import EditAd from 'components/EditAd/EditAd';

const EditModal = (props) => {

    const formRef = useRef(null);
    
    function forceSubmit() {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            keyboard={true}
            centered
        >
                <Modal.Header className='text-center'>
                    <Modal.Title>Edit {props.title.toLowerCase()}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {
                        props.data.link_id !== undefined ?
                        <EditLink 
                            link_id = {props.data.link_id}
                            link_short = {props.data.link_short}
                            link_long = {props.data.link_long}
                            link_description = {props.data.link_description}
                            formRef = {formRef}
                        />
                        :
                        <EditAd 
                            ad_id = {props.data.ad_id}
                            ad_name = {props.data.ad_name}
                            ad_info = {props.data.ad_info}
                            createdAt = {props.data.createdAt}
                            formRef = {formRef}
                        />
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>Close</Button>
                    <Button variant="primary" onClick={forceSubmit}>Save changes</Button>
                </Modal.Footer>
        </Modal>
    );
}

export default EditModal;