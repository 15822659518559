import './DetallesLink.scss'


const DetallesLink = (props) => {

    function changeFormatDate(stringDate) {
        const date = new Date(stringDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formatter = new Intl.DateTimeFormat('es-ES', options);
        return formatter.format(date);
    }

    return (
        <div className="link d-flex flex-column align-items-center">
            <div className='align-items-center'>
                <div className='align-items-center'>
                    <h4>Link_short</h4>
                </div>
                <div className='text-center'>
                    {props.link_short}
                </div>
            </div>

            <div className='align-items-center'>
                <div className='text-center'>
                    <h4>Link_long</h4>
                </div>
                <div className='text-center'>
                    {props.link_long}
                </div>
            </div>

            <div>
                <div>
                    <h4>Link_description</h4>
                </div>
                <div className='text-center'>
                    {
                        props.link_description !== null ?
                            <p>{props.link_description}</p>
                            :
                            "Vacio"
                    }
                </div>
            </div>
            <div className='align-items-center'>
                <div className='align-items-center'>
                    <h4>Visits</h4>
                </div>
                <div className='text-center'>
                    <div>
                        {props.visits.length}
                    </div>
                </div>
            </div>
            <div>
                <div className='text-center'>
                    <h4>Created At</h4>
                </div>
                <div className='text-center'>
                    {changeFormatDate(props.createdAt)}
                </div>
            </div>

        </div>
    );
}

export default DetallesLink;