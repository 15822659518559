import DataRow from 'components/GenericTable/DataRow/DataRow';
import React, { useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'services/firebase';
import './DataList.scss';

const DataList = (props) => {

    const parentProps = props.props;
    const [user] = useAuthState(auth);
    const [copied, setCopied] = useState(false);

    const EventHandler = async (type, id) => {
        if (type === "Delete") {
            await DeleteData(id);
        }
        if (type === "Edit") {
            await EditData(id);
        }
        if (type === "Copy") {
            await CopyData(id);
        }
        if (type === "View") {
            await ViewData(id);
        }
    }

    const ViewData = async (id) => {
        props.onView(id)
    }

    const CopyData = async (link) => {
        const url = new URL(window.location.href);
        const redirectUrl = url.protocol + "//" + url.host + "/" + link.link_short;
        navigator.clipboard.writeText(redirectUrl);
        setCopied(true);
    }

    const EditData = async (id) => {
        props.onEdit(id)
    }

    const DeleteData = async (id) => {
        const response = await parentProps.deleteData(id, user.accessToken);
        if (response.status_code === 200) {
            props.onDelete(response.body);
        } 
    }

    return (
        <div>
            <div className="DataList__header">
                <div className="DataList__header__short">
                    {
                        props.props.title === "Links"?
                        <h4>Link_short</h4>
                        :
                        <h4>Ad_name</h4>

                    }
                </div>
                <div className="DataList__header__options">
                    <h4>Options</h4>
                </div>
            </div>
            {
                props.data?.length !== 0 ?
                    props.data?.map((data) => (
                        <DataRow
                            data={data}
                            key={data[parentProps.identifier]}
                            onEvento={EventHandler}
                            props={parentProps}
                            copied={copied}
                        />
                    ))
                    :
                    <div className="DataList__empty">
                        <h6>There are no data to display.</h6>
                    </div>
            }
        </div>
    );
}

export default DataList;