import CreateLink from "components/CreateLink/CreateLink";
import { auth } from 'services/firebase.js';
import { useAuthState } from 'react-firebase-hooks/auth';
import "./Home.scss";

const Home = () => {

    const [user] = useAuthState(auth);

    return (
        <>
            <div className="home">
                <div className="home__container">
                    <div className="home__container__left">
                        <h2 className="home__container__title"><span className="navbar__logo-container home__container__title">JOIV:</span> Short, Fast</h2>
                        <p className="home__container__subtitle">Join the community of people who are passionate about the same things you are.</p>
                        {
                            user ? 
                            <div className="home__container__links">
                                <a href="/dashboard" className="home__container__links__link">Go to Dashboard</a>
                            </div>
                            :
                            <div className="home__container__links">
                                <a href="/login" className="nav-login">Login</a>
                                <a href="/register" className="nav-register">Register</a>
                            </div>

                        
                        }
                    </div>
                    <div className="home__container__right">
                        <CreateLink />
                    </div>
                </div>
            </div>
        </>
    )

};

export default Home;