import NavBar from "components/NavBar/NavBar";
import { Outlet } from "react-router-dom";
import authImg from "assets/images/auth.png";
import "./Auth.scss";

const AuthLayout = () => {
    return (
        <>
            <NavBar />
            <div className="auth-layout">
                <div className="auth-layout__content">
                    <div className="auth-layout__inner">
                        <div className="auth-layout__image">
                            <img src={authImg} width="300" style={{ position: 'relative' }} alt="login" />
                        </div>
                        <div className="auth-layout__form">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthLayout;