import FormElement from 'components/FormElement/FormElement';
import LinkService from 'services/links.service';
import { useAuthState } from 'react-firebase-hooks/auth';
import useStateHandler from 'hooks/useGlobalState';
import { auth } from 'services/firebase';
import { useState } from 'react';
import './EditLink.scss'

const EditLink = (props) => {

    const { state, stateHandler } = useStateHandler();
    const [newData, setNewData] = useState({ ...props });
    const [error, setError] = useState(null);
    const [user] = useAuthState(auth);

    const ErrorHandler = (data) => {
        if (data.attribute && data.reason) {
            if (data.attribute === 'link_short' && (data.reason.includes('duplicate') || data.reason.includes('exists') || data.reason.includes('unique'))) {
                setError({ attribute: 'link_short', reason: 'Short URL already exists. Please, try another one' });
            } else {
                setError(data);
            }
        } else {
            setError({ attribute: '', reason: 'Something went wrong' });
        }
    }

    function dataValidation(body) {
        const { link_long, link_short } = body;
        if (!link_long) {
            ErrorHandler({ attribute: 'link_long', reason: 'Long URL is required' });
        }
        const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
        if (!urlRegex.test(link_long)) {
            ErrorHandler({ attribute: 'link_long', reason: 'Long URL is not valid' });
        }
        return error ? false : true;
    }

    async function onSubmit(e) {
        e.preventDefault();

        const body = {
            link_id: newData.link_id,
            link_short: newData.link_short,
            link_long: newData.link_long,
            link_description: newData.link_description
        }

        setError(null);

        if (!dataValidation(body)) {
            return;
        }

        const response = await LinkService.updateLink({
            token: await user.getIdToken(),
            ...body
        });

        if (response.ok) {
            setError(null);
            // Get all keys in state that start with the title of the data
            const keys = Object.keys(state).filter(key => key.startsWith('Links'));
            // Delete the data from the state
            keys.forEach(key => {
                stateHandler.remove(key);
            });
        } else {
            ErrorHandler(response.body);
        }

    }

    return (
        <form className="link d-flex flex-column align-items-center" onSubmit={onSubmit} ref={props.formRef}>
            <div className='align-items-center'>
                <div className='text-center line-input'>
                    <FormElement
                        label="Link_short"
                        name="link_short"
                        type="text"
                        error={error}
                        value={newData.link_short}
                        onChange={e => setError({
                            attribute: 'link_short',
                            reason: 'Short URL can not be changed'
                        })}
                    />
                    <div className="focus-line"></div>
                </div>
            </div>

            <div>
                <div className='text-center line-input'>
                    <FormElement
                        label="Link_long"
                        name="link_long"
                        type="text"
                        error={error}
                        value={newData.link_long}
                        onChange={e => setNewData({ ...newData, link_long: e.target.value })}
                    />
                    <div className="focus-line"></div>
                </div>
            </div>

            <div>
                <div className='text-center line-input'>
                    <FormElement
                        label="Link_description"
                        name="link_description"
                        type="text"
                        error={error}
                        value={newData.link_description ?? ''}
                        onChange={e => setNewData({ ...newData, link_description: e.target.value })}
                    />
                    <div className="focus-line"></div>
                </div>
            </div>
        </form>
    );
}

export default EditLink;