import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'services/firebase';

const PrivateRoute = () => {

    const [user, loading, error] = useAuthState(auth);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!user) {
        return <Navigate to="/login" />;
    }

    return (
        <div>
            <Outlet />
        </div>
    )

}

export default PrivateRoute;