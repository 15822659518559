import React from 'react';
import './DataPagination.scss';
import Pagination from 'react-bootstrap/Pagination';

const DataPagination = ({ numberOfPages, current, setPage }) => {

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <div className="DataPagination__container">
            <Pagination>
            {
                Array.from(Array(numberOfPages)).map((_, index) => {
                    return (
                        <Pagination.Item className='item' activeLabel='' key={index} active={index + 1 === current} onClick={(e) => handlePageChange(e, index + 1)}>
                            {index + 1}
                        </Pagination.Item>
                    );
                })
            }
            </Pagination>
        </div>           
    );
}

export default DataPagination;