import store from 'store';
import router from 'routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import 'index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

serviceWorkerRegistration.register();

root.render(
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
);
