import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useRef } from 'react';
import CreateLink from 'components/CreateLink/CreateLink';
import CreateAd from 'components/CreateAd/CreateAd';

const CreateModalLink = (props) => {

    const formRef = useRef(null);

    function forceSubmit() {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            keyboard={true}
            centered
        >
                <Modal.Header className='text-center'>
                    <Modal.Title>Create {props.title.toLowerCase()}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {
                        props.title.toLowerCase() === "links" ?
                        <CreateLink formRef={formRef} />
                        :
                        <CreateAd formRef={formRef} />
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
                    <Button variant="primary" onClick={forceSubmit}>{"Create " + props.title.toLowerCase().substring(0, props.title.length - 1)}</Button>
                </Modal.Footer>
        </Modal>
    );
}

export default CreateModalLink;