import fetchWrapper from "./fetch.service";
import config from "config/default.config";
const API_URL = config.backend.url + '/link/';

async function createLink(params) {

    if (!params.link_short || !params.link_long) {
        return { error: "URL and title are required" };
    }
    const { response, data } = await fetchWrapper.post(API_URL, { body: params, injectToken: params.token });

    return {
        body: data,
        status_code: response.status,
        ok : response.ok
    }

}

async function getLinksUser(params) {
    const { response, data } = await fetchWrapper.get(API_URL + 'me/?' + new URLSearchParams(params.query).toString(), { injectToken: params.token });

    return {
        body: data,
        status_code: response.status,
        ok: response.ok
    }
}

async function getLink(link_id, token) {
    const { response, data } = await fetchWrapper.get(API_URL + link_id, { injectToken: token });
    return {
        body: data,
        status_code: response.status
    }
}

async function viewLink(short_url, token) {
    const { response, data } = await fetchWrapper.get(config.backend.url + "/l/" + short_url, { injectToken: token });
    return {
        body: data,
        status_code: response.status,
        ok : response.ok
    }

}

async function updateLink(params) {
    const { response, data } = await fetchWrapper.put(API_URL + params.link_id, { body: params, injectToken: params.token });
    return {
        body: data,
        status_code: response.status,
        ok: response.ok
    }
}

async function deleteLink(link_id, token) {
    const { response, data } = await fetchWrapper.delete(API_URL + link_id, { injectToken: token });
    return {
        body: data,
        status_code: response.status,
        ok: response.ok
    }
}

const linksService = {
    createLink,
    getLinksUser,
    getLink,
    viewLink,
    updateLink,
    deleteLink
};

export default linksService;