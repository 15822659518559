import Home from "pages/public/Home/Home";
import Login from "pages/public/Login/Login";
import Register from "pages/public/Register/Register";
import Redirect from "pages/public/Redirect/Redirect";
import ForgotPassword from "pages/public/ForgotPassword/ForgotPassword";
import AuthLayout from "layouts/Auth/Auth.layout";
import FullLayout from "layouts/Full/Full.layout";

const publicRoutes = [

    {
        element: <FullLayout />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/home",
                element: <Home />,
            },
            {
                path: "/:short_url",
                element: <Redirect />,
            },
        ],
    },
    {
        element: <AuthLayout />,
        children: [
            {
                path: "/login",
                element: <Login />,
            },
            {
                path: "/forgot-password",
                element: <ForgotPassword />,
            },
            {
                path: "/register",
                element: <Register />,
            },
        ],
    },
];

export default publicRoutes;