import './FormSubmit.scss'

const FormSubmit = ({ text, onSubmit, disabled }) => {
    return (
        <div className="form__button-wrapper">
            {
                onSubmit ? 
                    <button className="form__button" onClick={onSubmit} disabled={disabled}>{text}</button>
                    : 
                    <button className="form__button" type="submit" disabled={disabled}>{text}</button>
            }
            
        </div>
    );
}

export default FormSubmit;