const GlobalActions = {
    set: (attribute, value, opts) => {
        if(opts?.persist) localStorage.setItem(opts.persist.key, value);
        
        return {
            type: '@global/set',
            payload: {
                attribute,
                value
            }
        }
    },

    remove: (attribute) => {
        return {
            type: '@global/remove',
            payload: {
                attribute
            }
        }
    },
}

export default GlobalActions;