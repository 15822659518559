import React from "react";
import "./BaseError.scss";

const BaseError = (props) => {

    return (
        <>
            <div className="contain">
                <div className="texto">
                    <h1 className="">{props.errorCode} {props.errorMessage}</h1>
                    <p className="">{props.errorDetails}</p>
                </div>
                <div className="divImagen">
                    <img className="imagen" src={props.img} alt="404" />
                </div>
            </div>
        </>
    );

};

export default BaseError;