import NavBar from "components/NavBar/NavBar";
import DataGetter from "components/GenericTable/DataGetter/DataGetter";
import Visits from "components/Visits/Visits";
import LinksService from "services/links.service";
import AdsService from "services/ads.service";
import "./Dashboard.scss";

const Dashboard = () => {

    return (
        <>
            <NavBar />
            <div className="dashboard">
                <div className="dashboard__container">
                    <div className="dashboard__container__header">
                        <h1>Dashboard</h1>
                    </div>
                    <div className="dashboard__container__content">
                        <div className="mb-5">
                            <Visits />
                        </div>
                        <div className="mb-5">

                            <DataGetter
                                title="Links"
                                identifier="link_id"
                                filterBy="link_short"
                                createData={LinksService.createLink}
                                getData={LinksService.getLink}
                                getAllData={LinksService.getLinksUser}
                                deleteData={LinksService.deleteLink}
                                updateData={LinksService.updateLink}
                            />
                        </div>
                        <div className="mb-5">
                            <DataGetter
                                title="Ads"
                                filterBy="ad_name"
                                identifier="ad_id"
                                createData={AdsService.createAd}
                                updateData={AdsService.updateAd}
                                deleteData={AdsService.deleteAd}
                                getAllData={AdsService.getAdsUser}
                                getData={AdsService.viewAd}
                                copyData={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

};

export default Dashboard;