import React from 'react';
import { auth, logout } from 'services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import useGlobalState from 'hooks/useGlobalState';
import { useNavigate } from 'react-router-dom';
import './NavBar.scss';

const NavBar = () => {

    const navigate = useNavigate();
    const global = useGlobalState();
    const [user] = useAuthState(auth);

    return (
        <>
            <div className='navbar__container'>
                <div className="navbar__logo-container" href="/home">
                    {/* <img alt="JOIV" src="/imagen/logo.png" /> */}
                    <p style={{ margin: 0 }}>JOIV</p>
                </div>
                <div className="navbar__link-container">
                    <div className="navbar__link-container__left">
                        <a onClick={() => { navigate('/'); }}>Home</a>
                        <a onClick={() => { navigate('/links'); }}>Links</a>
                        <a onClick={() => { navigate('/ads'); }}>Ads</a>
                    </div>
                    <div className="navbar__link-container__right">
                        {user && <a className="nav-dashboard" onClick={() => { navigate('/dashboard') }}>Dashboard</a>}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        {user && <a className="nav-logout" onClick={() => {
                            logout();
                        }}>Logout</a>}
                        {!user && <a className="nav-login" onClick={() => { navigate('/login') }}>Login</a>}
                        {!user && <a className="nav-register" onClick={() => { navigate('/register') }}>Register</a>}

                    </div>
                </div>
                {/* Hamburguer menu */}
                <div className="navbar__link-container-mobile" onClick={() => {
                    const menu = document.querySelector('.navbar__link-container-mobile__menu');
                    menu.classList.toggle('mobile-hide');
                }}>
                    <div className="navbar__link-container-mobile__hamburguer">
                        <div className="navbar__link-container-mobile__hamburguer__line"></div>
                        <div className="navbar__link-container-mobile__hamburguer__line"></div>
                        <div className="navbar__link-container-mobile__hamburguer__line"></div>
                    </div>
                </div>
                <div className="navbar__link-container-mobile__menu mobile-hide">
                    <div className="navbar__link-container-mobile__menu__close" onClick={() => {
                        const menu = document.querySelector('.navbar__link-container-mobile__menu');
                        menu.classList.toggle('mobile-hide');
                    }}>
                        X
                    </div>
                    <div className="navbar__logo-container-mobile" href="/home">
                        {/* <img alt="JOIV" src="/imagen/logo.png" /> */}
                        <p style={{ margin: 0 }}>JOIV</p>
                    </div>
                    <a onClick={() => { navigate('/'); const menu = document.querySelector('.navbar__link-container-mobile__menu'); menu.classList.toggle('mobile-hide'); }}>Home</a>
                    <a onClick={() => { navigate('/links'); const menu = document.querySelector('.navbar__link-container-mobile__menu'); menu.classList.toggle('mobile-hide'); }}>Links</a>
                    <a onClick={() => { navigate('/ads'); const menu = document.querySelector('.navbar__link-container-mobile__menu'); menu.classList.toggle('mobile-hide'); }}>Ads</a>
                    {user && <a className="nav-dashboard" onClick={() => { navigate('/dashboard') }}>Dashboard</a>}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {user && <a className="nav-logout" onClick={() => {
                        logout();
                    }}>Logout</a>}
                    {!user && <a className="nav-login" onClick={() => { navigate('/login') }}>Login</a>}
                    {!user && <a className="nav-register" onClick={() => { navigate('/register') }}>Register</a>}
                </div>
            </div>
        </>
    )

};

export default NavBar;