import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "config/default.config";
import { useRef } from "react";
import LinksService from "services/links.service";
import "./Redirect.scss";

const Redirect = () => {

    const timerRef = useRef(null);
    const adWallRef = useRef(null);
    const { short_url } = useParams();
    const navigate = useNavigate();

    // Request to server to get the long url
    useEffect(() => {
        async function getLongUrl() {
            const response = await LinksService.viewLink(short_url);
            const data = response.body;
            if (response.ok) {
                if (data.ad.ad_info.ad_type === "iframe") {
                    adWallRef.current.innerHTML = data.ad.ad_info.frame;
                } else {
                    adWallRef.current.innerHTML = `<img src="${data.ad.ad_info.image}" />`;
                }
                setTimeout(() => {
                    // Redirect to long url
                    window.location.assign(data.link.link_long);
                }, config.redirect.delay);
                // Start timer
                let timer = Math.trunc(config.redirect.delay / 1000);
                const interval = setInterval(() => {
                    timerRef.current.innerHTML = timer;
                    timer--;
                }, 1000);
                // Stop timer
                setTimeout(() => {
                    clearInterval(interval);
                }, config.redirect.delay);
            } else {
                // Navigate to 404 page
                navigate('/404');
            }
        };
        getLongUrl();
    });

    return (
        <div className="redirect__container">
            <h1 className="redirect__title">Redirect</h1>
            <div className="redirect__timer__container">
                <p>Redirecting in <span id="timer" ref={timerRef}>{Math.trunc(config.redirect.delay / 1000)}</span> seconds</p>
            </div>
            <div className="redirect__ad-wall" ref={adWallRef}>
            </div>
        </div>
    );
};

export default Redirect;