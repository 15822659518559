import { configureStore } from '@reduxjs/toolkit';
import MainReducer, { INITIAL_STATE } from './reducers';

//Global state initialization
const store = configureStore({
    reducer: MainReducer,
    preloadedState: INITIAL_STATE,
    devTools: true, // change it to false in production
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
        {
            serializableCheck: false,
        }
    ),
});

store.subscribe(() => {
    // eslint-disable-next-line
    const state = store.getState();
    //Callback used to persist data in local storage if needed
    if (state.user_settings?.theme) {
        //localStorage.setItem('theme', state.user_settings.theme);
    }


    localStorage.setItem('token', state.token);

});

export default store;