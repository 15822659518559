import PrivateRoute from "pages/private/PrivateRoute/PrivateRoute";
import Dashboard from "pages/private/Dashboard/Dashboard";
import Links from "pages/private/Links/Links";
import Ads from "pages/private/Ads/Ads";

const privateRoutes = [
    {
        element : <PrivateRoute />,
        children : [
            {
                path: "/dashboard",
                element: <Dashboard />,
            },
            {
                path: "/links",
                element: <Links />,
            },
            {
                path: "/ads",
                element: <Ads />,
            },
        ]
    }
];

export default privateRoutes;