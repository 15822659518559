import FormElement from 'components/FormElement/FormElement';
import SignInWithGoogle from 'components/SignInWithGoogle/SignInWithGoogle';
import FormSubmit from 'components/FormSubmit/FormSubmit';
import React, { useState } from 'react';
import { registerWithUsernameAndPassword, auth, logout } from 'services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import './Register.scss';

const Register = (userParam) => {

    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [user, setUser] = useState(userParam ?? {
        user_name: '',
        user_email: '',
        user_pass: '',
        user_pass_confirm: ''
    });

    const [userState] = useAuthState(auth);

    const dataValidation = (data) => {
        const { user_email, user_name, user_pass, user_pass_confirm } = data;
        let bool = true;

        if(!user_email) {
            setError({ attribute: 'user_email', reason: 'Email is required' });
            bool = false;
        }
        else if(!user_name || user_name.length < 3) {
            setError({ 
                attribute: 'user_name', 
                reason: 'Name must be at least 3 characters long' 
            });
            bool = false;
        }
        else if(!user_pass || user_pass.length < 6 || /\d/.test(user_pass) === false) {
            setError({
                attribute: 'user_pass',
                reason: 'Password must be at least 6 characters long and contain at least one number'
            });
            bool = false;
        }
        else if(user_pass !== user_pass_confirm) {
            setError({
                attribute: ['user_pass', 'user_pass_confirm'],
                reason: 'Passwords do not match'
            });
            bool = false;
        }
        return bool;
    }

    async function onSubmit(e) {
        e.preventDefault();
        setError(null);

        if(userState) {
            await logout();
            return;
        }

        const data = {
            user_email: e.target.elements['user_email'].value,
            user_name: e.target.elements['user_name'].value,
            user_pass: e.target.elements['user_pass'].value,
            user_pass_confirm: e.target.elements['user_pass_confirm'].value
        };

        if(!dataValidation(data)) {
            console.log('Error in dataValidation', error);
            return;
        }

        const response = await registerWithUsernameAndPassword(data.user_email, data.user_pass, data.user_name);
        if(!response || (response.reason && response.attribute)) {
            setError(response);
        } else {
            navigate('/dashboard');
        }

    }

    return  (
        <>
            <div className="register">
                <div className="register__container">
                    <form id="register__form" onSubmit={onSubmit}>
                        <h2>Sign Up</h2>
                        <FormElement
                            label="Email"
                            name="user_email"
                            type="email"
                            value={user.user_email}
                            onChange={e => setUser({ ...user, user_email: e.target.value })}
                            error={error}
                            placeholder="johndoe@example.com"
                            autocomplete={"email"}
                        />
                        <FormElement
                            label="Name"
                            name="user_name"
                            type="text"
                            value={user.user_name}
                            onChange={e => setUser({ ...user, user_name: e.target.value })}
                            error={error}
                            placeholder="John Doe"
                            autocomplete={"name"}
                        />
                        <FormElement
                            label="Password"
                            name="user_pass"
                            type="password"
                            value={user.user_pass}
                            onChange={e => setUser({ ...user, user_pass: e.target.value })}
                            error={error}
                            placeholder="********"
                            autocomplete={"new-password"}
                        />
                        <FormElement
                            label="Confirm Password"
                            name="user_pass_confirm"
                            type="password"
                            value={user.user_pass_confirm}
                            onChange={e => setUser({ ...user, user_pass_confirm: e.target.value })}
                            error={error}
                            placeholder="********"
                            autocomplete={"new-password"}
                        />
                        <FormSubmit text="Sign Up"/>
                    </form>
                    <div className="register-form__oauth">
                        <SignInWithGoogle />
                    </div>
                    <div className='register-form__login'>
                        Already have an account? <a href="/login" className="register__login">Sign in</a>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Register;