let config = {
    backend: {
        host: 'practicaadi.jlsg.es',
        port: 443,
        secure: true
    },
    firebaseConfig: {
        apiKey: "AIzaSyCZrhx9DnzgYmL4lyomVyOu6IIxIxJKE5Q",
        authDomain: "practicaadi-7fb23.firebaseapp.com",
        projectId: "practicaadi-7fb23",
        storageBucket: "practicaadi-7fb23.appspot.com",
        messagingSenderId: "154743956033",
        appId: "1:154743956033:web:0ace29c405bc420e791cc0",
        measurementId: "G-YMSF7EF7TF"
    },
    redirect: {
        delay : 5500,
    }
};

config.backend.url = (config.backend.secure ? 'https://' : 'http://') + config.backend.host + ':' + config.backend.port;

export default config;
