import React from "react";
import NavBar from "../../../components/NavBar/NavBar";
import BaseError from "../BaseError/BaseError";
import imgBuena from "../../../assets/images/500.jpg";

const ServerError = () => {
    return (
        <>
            <NavBar />
            <BaseError
                errorCode="500"
                errorMessage="Server Error"
                errorDetails="Sorry, the server is currently unavailable."
                img = {imgBuena}
            />
        </>
        
    );
}


export default ServerError;